/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from '../Component/Navbar';
import SideNavbar from '../Component/SideNavbar'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../Styles/Card.module.css';
// import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import input from '../Styles/Form.module.css'
import '../Styles/BookingHalls.css';
import { Modal } from '@material-ui/core';
import '../../src/Styles/DharaniHall.css';
import { useLocation } from "react-router-dom";
import nav from '../../src/Styles/Navbar.module.css'
import moment from 'moment';

function ResponsiveExample() {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateBook, setDateBook] = useState([]);
  const [viewSlotData, setViewSlotData] = useState([]);
  const [slotRow, setSlotRow] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [refreshSlots, setRefreshSlots] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [capacityData, setCapacityData] = useState([]);
  const [selectStartTimeSlot, setSelectStartTimeSlot] = useState('');
  const [selectEndTimeSlot, setSelectEndTimeSlot] = useState('');
  // const selectedDay = new Date();
  const utcDate = Date.UTC(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate());
  const year = new Date(utcDate).getUTCFullYear();
  const month = ('0' + (new Date(utcDate).getUTCMonth() + 1)).slice(-2);
  const day = ('0' + new Date(utcDate).getUTCDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  console.log("dateeee", formattedDate)

  const dataloginname = localStorage.getItem("userName").replace(/^"(.*)"$/, '$1');
  console.log("locallllllusername", dataloginname)
  const dataloginmobile = localStorage.getItem("userMobile").replace(/^"(.*)"$/, '$1');
  console.log("localllllllllusermobile", dataloginmobile)
  const dataloginEmail = localStorage.getItem("userEmail").replace(/^"(.*)"$/, '$1');
  console.log("locallllllllluserEmail", dataloginEmail)
  // function viewSlot() {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   var raw = JSON.stringify({
  //     "slt_date": formattedDate
  //   });
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };
  //   fetch("http://122.185.119.138:8300/sriHall/booking/getSlotDetails", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       setViewSlotData(result.data);

  //     })
  //     .catch(error => console.log('error', error));
  // }
  console.log(viewSlotData, "viewwwwwwwww")
  // useEffect(() => {

  //   viewSlot();
  // }, []);


  const handleOpenDialog = (hallName, slotName) => {
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  // Function to check if a given date is a weekend day
  function isWeekend(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
    console.log("seeeeeeeeee", day)
  }

  // Custom tile class name function to highlight weekends
  function getTileClassName({ date, view }) {
    if (view === 'month' && isWeekend(date)) {
      return 'weekend';
    }
  }
  console.log("seeeeeeeeee", selectedDay)

  //useeffect for clicking date
  useEffect(() => {
    if (selectedDay) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "slt_date": formattedDate,
        "hall_name": "Dharani"
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("http://122.185.119.138:8300/sriHall/booking/getSlotDetailsByHallNameAndDt", requestOptions)
        .then(response => response.json())
        .then(result => {
          setViewSlotData(result.data);
        })
        .catch(error => console.log('error', error));
    }
  }, [selectedDay]);

  // useEffect(() => {
  //     fetch(`http://122.185.119.138:8300/sriHall/booking/getSlotDetails/${formattedDate}`)
  //       .then((result) => {
  //         result.json().then((resp) => {
  //             setViewSlotData(resp.data);

  //         });
  //       });
  //   }, []);
  console.log("slot daysssss", formattedDate)
  const handleDateClick = (date) => {
    // Update any other state or perform any other action here
    setSelectedDay(date);
  }

  function EditImage(item, hallName, slotName) {
    console.log(item, "data");
    setSelectedHall(item);
    const rowhallname = hallName;
    console.log(rowhallname, "oooo")
    setSelectedSlot(rowhallname);

    // console.log(rowslot,"ssssss")

  }

  const [idedit, setidedit] = useState("");
  const [cusName, setCusName] = useState("");
  const [cusAdd, setCusAdd] = useState("");
  const [mobileNumber1, setMobileNumber1] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [email, setEmail] = useState("");
  const [adharNumber, setAdharNumber] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [advance, setAdvance] = useState("");
  const [purpose, setPurpose] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [pax, setPax] = useState("");
  const [bookedby, setBookedBy] = useState("");
  const [occasionDate, setOccasionDate] = useState("");

  console.log(cusName, "cusssssssssssssnameeeeee")
  console.log(cusAdd, "addddd")
  console.log(mobileNumber1, "m1")
  console.log(mobileNumber2, "m2")
  console.log(email, "emailll")
  console.log(adharNumber, "adhr")
  console.log(totalAmount, "totalamt")
  console.log(advance, "advance")
  console.log(purpose, "purpose")
  console.log(startTime, "starttime")
  console.log(endTime, "endtime")
  console.log(pax, "pax")
  console.log(bookedby, "bokkkeddddby")
  console.log(occasionDate, "occasiondateee")

  const [selectedHall, setSelectedHall] = useState('');


  console.log("halllllll", selectedHall)
  const submit = (e, hallName, slotName) => {
    // const rowhallname = hallName;
    console.log(selectedHall, "oooo")
    // const rowslot = slotName
    console.log(selectedSlot, "ssssss")

    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = {
      // "slt_name": "mrng",
      "hall_name": selectedHall,
      "slt_date": formattedDate,
      "slt_stime": startTime,
      "slt_etime": endTime,
      "bkg_hall_name": selectedHall,
      "bkg_cust_name": cusName,
      "bkg_cust_add": cusAdd,
      "bkg_mbn1": mobileNumber1,
      "bkg_mbn2": mobileNumber2,
      "bkg_adr_num": adharNumber,
      "bkg_cust_email": email,
      "bkg_prpse": purpose,
      "bkg_pax": pax,
      "bkg_advnce": advance,
      "bkg_total": totalAmount,
      "bkg_bookedby": dataloginEmail,
      "bkg_Occassio_dte": formattedDate,
      "bkg_inTime": selectedTimeIn,
      "bkg_outTime": selectedTimeOut
    };
    if (selectedSlot === "avil_mrng") {
      raw.slot_name = "mrng";
    } else if (selectedSlot === "avil_evng") {
      raw.slot_name = "evng";
    } else {
      raw.slot_name = "";
    }

    if (selectedSlot === "avil_mrng") {
      raw.slt_name = "mrng";
    } else if (selectedSlot === "avil_evng") {
      raw.slt_name = "evng";
    } else {
      raw.slt_name = "";
    }
    console.log("rawwwwww", raw)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: 'follow'
    };
    // fetch("http://122.185.119.138:8300/sriHall/booking/slotBooking", requestOptions)
    fetch("http://122.185.119.138:8300/sriHall/booking/slotBooking", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, "print data")
        if (result.status == 200) {
          setOpend(false);
          alert("Successfully Booked")
          setRefreshSlots(true);
          window.location.reload(selectedDay);

        }
      })
      .catch(error => console.log('error', error));
  }

  const uniqueHallNames = viewSlotData.reduce((acc, item) => {
    if (!acc.includes(item.hall_name)) {
      acc.push(item.hall_name);
    }
    return acc;
  }, []);
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    setOpen(!open);
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [opend, setOpend] = useState(false);


  const handleOpen = (hallName, slotName) => {
    setSelectedHall(hallName);
    setSelectedSlot(slotName);
    setSelectedTimeIn('');
  setSelectedTimeOut('');
    setOpend(true);
    history.pushState({ modalOpen: true }, "");
  };

  const handleClose = () => {
    setOpend(false);
    history.pushState({ modalOpen: false }, "");
  };

  // Listen for the popstate event for opend
  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.state && !event.state.modalOpen) {
        setOpend(false);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  useEffect(() => {
    function hallcapacity() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        hall_name: selectedHall,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        fetch(
          "http://122.185.119.138:8300/sriHall/booking/getHallCapacity",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setCapacityData(result.results);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log("error", error);
      }
    }

    hallcapacity();
  }, [selectedHall]);

  // const handleClose = () => {
  //   setOpend(false);
  // };

  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.state && !event.state.modalOpen) {
        setOpenCustDet(false);
      }
    };
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const [openCustDet, setOpenCustDet] = useState(false);
  const handleOpenCust = (hallName, slotName) => {
    setSelectedHall(hallName);
    setSelectedSlot(slotName);
    setOpenCustDet(true);
    history.pushState({ modalOpen: true }, "");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      "bkg_Occassio_dte": formattedDate,
      "slot_name": slotName,
      "bkg_hall_name": hallName
    };

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: 'follow'
    };

    fetch("http://122.185.119.138:8300/sriHall/booking/getCustomerDetails", requestOptions)
      .then(response => response.json())
      .then(result => {
        setCustomerData(result.results);
      })
      .catch(error => console.log('error', error));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "hall_name": hallName,
      "slot_name": slotName,
      "bkg_Occassio_dte": formattedDate
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("http://122.185.119.138:8300/sriHall/booking/getHallCapacity", requestOptions)
      .then(response => response.json())
      .then(result => {
        setCapacityData(result.results);
      })
      .catch(error => console.log('error', error));
  };


  console.log("slotttttcus", selectedSlot)
  // console.log("halllllcus", hallName)
  console.log("dateeecus", formattedDate)
  console.log("customerdetailssssssss", customerData)

  const handleCloseCust = () => {
    setOpenCustDet(false);
    history.pushState({ modalOpen: false }, "");
  };

  console.log("slotttttttttttttttttt", selectedSlot)
  const handleChangeStartTime = (event) => {
    setSelectStartTimeSlot(event.target.value);
  }
  const handleChangeEndTime = (event) => {
    setSelectEndTimeSlot(event.target.value);
  }
  const datevalid = moment(formattedDate).format('DD-MMM-YYYY')
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = ('0' + (today.getMonth() + 1)).slice(-2);
  const currentDay = ('0' + today.getDate()).slice(-2);
  const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  // TIME IN

  const [selectedTimeIn, setSelectedTimeIn] = useState('');
  const timeOptionsin = generateTimeOptionsIn();

  const handleTimeChangeIn = (event) => {
    setSelectedTimeIn(event.target.value);
  };

  function generateTimeOptionsIn() {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hour12Format = (hour % 12) || 12;
        const amPm = hour < 12 ? 'AM' : 'PM';
        const timeString = `${hour12Format.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${amPm}`;
        options.push(timeString);
      }
    }
    return options;
  }

  // TIME OUT
  const [selectedTimeOut, setSelectedTimeOut] = useState('');
  const timeOptionsout = generateTimeOptionsOut();


  const handleTimeChangeOut = (event) => {
    const selectedTimeOut = event.target.value;
  
    if (selectedTimeOut < selectedTimeIn) {
      // Perform an action when time out is earlier than time in
      // setSelectedTimeOut(selectedTimeOut);
      alert("Please select appropriate time");
    } else {
      // Perform an action when time out is equal to or later than time in
      setSelectedTimeOut(selectedTimeOut);
    }
  };
  
  function generateTimeOptionsOut() {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hour12Format = (hour % 12) || 12;
        const amPm = hour < 12 ? 'AM' : 'PM';
        const timeString = `${hour12Format.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${amPm}`;
        options.push(timeString);
      }
    }
    return options;
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <div>
        <Navbar />
      </div>
      <div >
        <Row>
          <Col md="3">
            <SideNavbar />
          </Col>

          <Col md="9">
            {/* <Row className='mt-4'>
                            <Col sm={4}>
                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={4}>
                                <label className='px-1'>
                                    <input type="text" style={{ width: 15, height: 15, backgroundColor: 'green' }} />
                                    <span style={{ color: 'black', fontFamily: 'bold', fontSize: 14 }}> Not Booked</span>
                                </label>&nbsp;

                                <label className='px-1'>
                                    <input type="text" style={{ width: 15, height: 15, backgroundColor: 'grey' }} />
                                    <span style={{ color: 'black', fontFamily: 'bold', fontSize: 14 }}> Booked</span>
                                </label>&nbsp;
                            </Col>
                        </Row> */}
            <Row className='mt-5'>
              <Col sm={6} className='mt-5'>
                <Row style={{ marginLeft: '20%' }}>
                  <div style={{ width: '800px' }}>
                    <Calendar
                      value={selectedDay}
                      onChange={handleDateClick}

                    />

                  </div>
                  <div className='mt-4'>
                    <label className='px-1'>
                      <input type="text" style={{ width: 15, height: 15, backgroundColor: 'green' }} />
                      <span style={{ color: 'black', fontFamily: 'bold', fontSize: 14 }}> Avaliable</span>
                    </label>&nbsp;

                    <label className='px-1'>
                      <input type="text" style={{ width: 15, height: 15, backgroundColor: 'grey' }} />
                      <span style={{ color: 'black', fontFamily: 'bold', fontSize: 14 }}> Booked</span>
                    </label>&nbsp;
                  </div>
                </Row>
              </Col>
              <Col sm={6} className='mt-5'>
                {selectedDay && (
                  <Row>

                    <Row className="justify-content-md-center mt-2">
                      <Row style={{ fontFamily: 'bold', marginLeft: '120%' }}><b><span>Date:   </span> {datevalid}</b></Row>
                      <table style={{ width: 500 }}>

                        <table style={{ fontFamily: 'bold' }}>
                          <thead >
                            <tr >
                              <th>Hall Name</th>
                              <th>Morning </th>
                              <th>Evening </th>
                            </tr>
                          </thead>
                          <tbody>
                            {uniqueHallNames.map((hallName, i) => {
                              const slots = viewSlotData.filter(item => item.hall_name === hallName);
                              return (
                                <tr key={i} >
                                  <td style={{ fontSize: 16 }}><b>{hallName}</b></td>

                                  <td>

                                    {slots.map((item, j) => {

                                      if (item.slt_mrng === "mrng" && formattedDate < currentDate) {
                                        return (
                                          <Card
                                            key={j}
                                            style={{
                                              marginBottom: '2px',
                                              backgroundColor: 'grey',
                                              color: 'white',
                                              width: 100,
                                              height: 50,
                                              cursor: 'pointer',
                                              marginLeft: 80,
                                            }}
                                            onClick={() => handleOpenCust(hallName, item.slt_mrng)}
                                          >
                                            <Card.Body>
                                              {item.slt_mrng ? <span>Booked</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      else if (item.slt_mrng === "avil_mrng" && formattedDate < currentDate) {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'green', color: 'white', width: 100, height: 50, marginLeft: 80 }}
                                          >
                                            <Card.Body >

                                              {item.slt_mrng ? <span>Avaliable</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      else if (item.slt_mrng === "avil_mrng") {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'green', cursor: 'pointer', color: 'white', width: 100, height: 50, marginLeft: 80 }}
                                            onClick={() => EditImage(hallName, item.slt_mrng, item) || handleOpen(hallName, item.slt_mrng)} >
                                            <Card.Body >

                                              {item.slt_mrng ? <span>Avaliable</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      else if (item.slt_mrng === "mrng") {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'grey', color: 'white', width: 100, height: 50, cursor: 'pointer', marginLeft: 80 }}
                                            onClick={() => handleOpenCust(hallName, item.slt_mrng)}>
                                            <Card.Body>

                                              {item.slt_mrng ? <span>Booked</span> : null}

                                            </Card.Body>
                                          </Card>
                                        );
                                      }

                                    })}

                                  </td>
                                  <td>
                                    {slots.map((item, j) => {
                                      if (item.slt_evng === "avil_evng" && formattedDate < currentDate) {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'green', color: 'white', width: 100, height: 50, marginLeft: 50 }}
                                          >
                                            <Card.Body >
                                              {/* <span > {item.slt_evng}</span> */}
                                              {item.slt_evng ? <span>Avaliable</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      else if (item.slt_evng === "evng" && formattedDate < currentDate) {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'grey', color: 'white', width: 100, height: 50, cursor: 'pointer', marginLeft: 50 }}
                                            onClick={() => handleOpenCust(hallName, item.slt_evng)}>
                                            <Card.Body>
                                              {/* <span>{item.slt_evng}</span> */}
                                              {item.slt_evng ? <span>Booked</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      else if (item.slt_evng === "avil_evng") {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'green', cursor: 'pointer', color: 'white', width: 100, height: 50, marginLeft: 50 }}
                                            onClick={() => EditImage(hallName, item.slt_evng, item) || handleOpen(hallName, item.slt_evng)}>
                                            <Card.Body >
                                              {/* <span > {item.slt_evng}</span> */}
                                              {item.slt_evng ? <span>Avaliable</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                      if (item.slt_evng === "evng") {
                                        return (
                                          <Card key={j} style={{ marginBottom: '2px', backgroundColor: 'grey', color: 'white', width: 100, height: 50, cursor: 'pointer', marginLeft: 50 }}
                                            onClick={() => handleOpenCust(hallName, item.slt_evng)}>
                                            <Card.Body>
                                              {/* <span>{item.slt_evng}</span> */}
                                              {item.slt_evng ? <span>Booked</span> : null}
                                            </Card.Body>
                                          </Card>
                                        );
                                      }
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>




                      </table>


                    </Row>
                  </Row>
                )}
              </Col>

            </Row>


          </Col>

          {/* POPUP */}
          <div>

            <Modal open={opend} onClose={handleClose} style={{ width: '100%', height: '100%' }}>
              <div style={{ width: '100%', height: '100%', backgroundColor: 'white', position: 'relative' }}>


                <div className='container-fluid'>

                  <Row style={{ fontFamily: 'bold', marginLeft: '10%' }}
                  >
                    <Col md={8}>

                      <form onSubmit={e => submit(e)} >
                        <Row style={{ fontFamily: 'bold', fontSize: 15 }} className='mt-3'>
                          <Card style={{ width: 750 }}>
                            <Card.Header className={styles.customercardheader}>
                              <div className='row mt-2' style={{ fontFamily: 'bold' }}>
                                <div class="col-3 col-sm-4" >
                                  <b style={{ fontFamily: 'bold' }}> Customer Details </b>
                                </div>

                                <div class="col-2 col-sm-2" >
                                  <b style={{ fontFamily: 'bold' }}>{selectedHall} </b>
                                </div>

                                <div class="col-2 col-sm-2" >
                                  <b style={{ fontFamily: 'bold' }}>  {datevalid} </b>
                                </div>

                                <div class="col-2 col-sm-4" >
                                  <b style={{ fontFamily: 'bold' }}> {selectedSlot === "avil_mrng" ? "Morning" : selectedSlot === "avil_evng" ? "Evening " : ""}</b>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body style={{ fontSize: 14 }}>
                              <Row md={12}>
                                <Col md={6}>
                                  <Row xs="3" className='mt-2' >
                                    <Col> <b> Name<span style={{ color: 'red' }}> *</span></b></Col>
                                    <Col><input type="text" placeholder='Customer Name' className={input.forminput} name="cusName" value={cusName} onChange={e => setCusName(e.target.value)} required /></Col>
                                  </Row>
                                  <Row xs="3" className='mt-2'>
                                    <Col> <b>Address<span style={{ color: 'red' }}> *</span></b></Col>
                                    <Col>
                                      <textarea
                                        placeholder='Address'
                                        className={input.forminput}
                                        name="cusAdd"
                                        value={cusAdd}
                                        onChange={e => setCusAdd(e.target.value)}
                                        rows={3} style={{ height: 120 }}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={6}>
                                  <Row xs="2" className='mt-2'>
                                    <Col> <b>Email<span style={{ fontSize: 10, opacity: 0.5 }}>  (optional)</span></b></Col>
                                    <Col><textarea type="text" placeholder='Email' className={input.forminput} style={{ height: 60 }} name="email" value={email} onChange={e => setEmail(e.target.value)} /></Col>
                                  </Row>
                                  <Row xs="2" className='mt-2'>
                                    <Col> <b> Mobile Number 1<span style={{ color: 'red' }}> *</span></b></Col>
                                    <Col><input type="text" placeholder='Mobile Number 1' className={input.forminput} name="mobileNumber1" value={mobileNumber1}
                                      //  onChange={e => setMobileNumber1(e.target.value)}
                                      onChange={e => {
                                        const inputValue = e.target.value;
                                        const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                        if (regex.test(inputValue)) {
                                          setMobileNumber1(inputValue);
                                        } else {
                                          alert('Please enter only integers.');
                                        }
                                      }} required /></Col>
                                  </Row>

                                  <Row xs="2" className='mt-2'>
                                    <Col> <b> Mobile Number 2<span style={{ fontSize: 10, opacity: 0.5 }}>   (optional)</span></b></Col>
                                    <Col><input type="text" placeholder='Mobile Number 2' className={input.forminput} name="mobileNumber2" value={mobileNumber2}
                                      //  onChange={e => setMobileNumber2(e.target.value)} 
                                      onChange={e => {
                                        const inputValue = e.target.value;
                                        const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                        if (regex.test(inputValue)) {
                                          setMobileNumber2(inputValue);
                                        } else {
                                          alert('Please enter only integers.');
                                        }
                                      }} /></Col>
                                  </Row>
                                  <Row xs="2" className='mt-2'>
                                    <Col> <b> Aadhaar Number<span style={{ fontSize: 10, opacity: 0.5 }}>   (optional)</span></b></Col>
                                    <Col><input type="text" placeholder='Aadhaar Number' className={input.forminput} name="adharNumber" value={adharNumber}
                                      // onChange={e => setAdharNumber(e.target.value)} 
                                      onChange={e => {
                                        const inputValue = e.target.value;
                                        const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                        if (regex.test(inputValue)) {
                                          setAdharNumber(inputValue);
                                        } else {
                                          alert('Please enter only integers.');
                                        }
                                      }} /></Col>
                                  </Row>

                                </Col>
                              </Row>

                            </Card.Body>
                          </Card>

                          <Card style={{ width: 750 }} className='mt-2'>
                            <Card.Header className={styles.customercardheader}>

                              <div className='row mt-2' style={{ fontFamily: 'bold' }}>
                                <div class="col-2 col-sm-4" >
                                  <b style={{ fontFamily: 'bold' }}> Hall Bookings </b>
                                </div>

                                <div class="col-2 col-sm-2" >

                                  <b > {selectedHall} </b>
                                </div>

                                <div class="col-2 col-sm-2" >
                                  <b style={{ fontFamily: 'bold' }}>{datevalid}</b>
                                </div>

                                <div class="col-2 col-sm-4" >
                                  <b style={{ fontFamily: 'bold' }}> {selectedSlot === "avil_mrng" ? "Morning" : selectedSlot === "avil_evng" ? "Evening " : ""}</b>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body style={{ fontSize: 14 }}>

                              {/* <Row xs="auto">
                    <Col>
                      <Row xs="auto" >
                        <Col>
                          <b>Time In<span style={{ color: 'red' }}> *</span></b>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center">
                            <input type="text" placeholder="In" style={{ width: 50, height: 25, backgroundColor: "rgb(225, 225, 225)", border: "none" }} name="startTime" value={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                            <select value={selectStartTimeSlot} onChange={handleChangeStartTime} className={nav.navdropdown} style={{ marginLeft: 3 }}>
                              <option value="0">Am/Pm</option>
                              <option value="Am">Am</option>
                              <option value="Pm">Pm</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row xs="auto">
                        <Col>
                          <b>Time Out<span style={{ color: 'red' }}> *</span></b>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center">
                            <input type="text" placeholder='Out' style={{ width: 50, height: 25, backgroundColor: 'rgb(225, 225, 225)', border: 'none' }} name="endTime" value={endTime} onChange={e => setEndTime(e.target.value)} required />
                            <select value={selectEndTimeSlot} onChange={handleChangeEndTime} className={nav.navdropdown} style={{ marginLeft: 3 }}>
                              <option value="0">Am/Pm</option>
                              <option value="Am">Am</option>
                              <option value="Pm">Pm</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}

                              <Row xs="auto">
                                <Col>
                                  <Row xs="auto" >
                                    <div>
                                      <label htmlFor="time">Time In<span style={{ color: 'red' }}> *</span></label>
                                      <select id="time" value={selectedTimeIn} onChange={handleTimeChangeIn} style={{ marginLeft: 10 }}>
                                        <option value="">-- Select a time --</option>
                                        {timeOptionsin.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      {/* <p>Selected Time: {selectedTimeIn}</p> */}
                                    </div>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row xs="auto">
                                    <div>
                                      <label htmlFor="time">Time Out<span style={{ color: 'red' }}> *</span>   </label>
                                      <select id="time" value={selectedTimeOut} onChange={handleTimeChangeOut} style={{ marginLeft: 10 }}>
                                        <option value="">-- Select a time --</option>
                                        {timeOptionsout.map((time, index) => (
                                          <option key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      {/* <p>Selected Time: {selectedTimeOut}</p> */}
                                    </div>
                                  </Row>
                                </Col>
                              </Row>

                              <Row md={12}>

                                <Row md={12} className='mt-2'>
                                  <Col md={12}>
                                    <Row xs="5" className="mt-2" style={{ display: "flex", width: "100%" }}>
                                      <Col>
                                        <b>Purpose<span style={{ color: 'red' }}> *</span></b>
                                      </Col>
                                      <Col xs={8} style={{ marginLeft: "-22px" }}>
                                        <textarea type="text" placeholder="Purpose" className={input.forminput} name="purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)} required style={{ width: 510, height: 70, marginTop: "5px" }} />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                <Row md={12} className='mt-2'>
                                  <Col md={6}>
                                    <Row xs="3" className='mt-2'>
                                      <Col> <b>Pax<span style={{ color: 'red' }}> *</span></b></Col>
                                      <Col><input type="text" placeholder='Pax' className={input.forminput} name="pax" value={pax}
                                        // onChange={e => setPax(e.target.value)}
                                        onChange={e => {
                                          const inputValue = e.target.value;
                                          const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                          if (regex.test(inputValue)) {
                                            setPax(inputValue);
                                          } else {
                                            alert('Please enter only integers.');
                                          }
                                        }} required /></Col>
                                    </Row>
                                  </Col>

                                  <Col md={6}>
                                    {handleOpen && selectedSlot &&
                                      <Row xs="3" className='mt-2'>
                                        <Col> <b>Capacity<span style={{ color: 'red' }}> *</span></b></Col>
                                        {capacityData.map((item) => (
                                          <Col key={item.id}><div className={input.formcustomer} style={{ width: 170 }}>{item.hall_cpcty}</div></Col>
                                        ))}

                                      </Row>}
                                  </Col>
                                </Row>

                                <Row md={12} className='mt-2'>
                                  <Col md={6}>
                                    <Row xs="3" className='mt-2'>
                                      <Col><b>Advance<span style={{ color: 'red' }}> *</span></b></Col>
                                      <Col>
                                        <input type="text" placeholder='Advance' className={input.forminput} name="advance" value={advance}
                                          //  onChange={e => setAdvance(e.target.value)} 
                                          onChange={e => {
                                            const inputValue = e.target.value;
                                            const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                            if (regex.test(inputValue)) {
                                              setAdvance(inputValue);
                                            } else {
                                              alert('Please enter only integers.');
                                            }
                                          }} required />
                                      </Col>
                                    </Row>


                                  </Col>
                                  <Col md={6}>
                                    <Row xs="3" className='mt-2'>
                                      <Col> <b>Total<span style={{ color: 'red' }}> *</span> </b></Col>
                                      <Col><input type="text" placeholder='Total Amount' className={input.forminput} name="totalAmount" value={totalAmount}
                                        onChange={e => {
                                          const inputValue = e.target.value;
                                          const regex = /^[0-9]*$/; // Regular expression to match only digits (0-9)

                                          if (regex.test(inputValue)) {
                                            setTotalAmount(inputValue);
                                          } else {
                                            alert('Please enter only integers.');
                                          }
                                        }} required /></Col>
                                    </Row>
                                  </Col>
                                </Row>

                              </Row>

                            </Card.Body>
                          </Card>

                        </Row>

                        <Row>
                          <Col md={7}>

                            <Row xs="4" className='mt-2'>
                              <b>Booked By</b>
                              <Col ><div className={input.formcustomer} style={{ width: 250, marginLeft: '-40px' }}>{dataloginname}-{dataloginmobile}</div></Col>
                            </Row>
                          </Col>
                          <Col md={5}>
                            <button type="submit" className='mt-2' style={{ width: 100, height: 40, backgroundColor: 'red', color: 'white', borderRadius: 5, fontFamily: 'bold' }}>Submit</button>&nbsp; &nbsp;
                            <button type="button" onClick={handleClose} style={{ width: 100, height: 40, backgroundColor: 'grey', color: 'white', borderRadius: 5, fontFamily: 'bold' }}>Cancel</button>
                          </Col>
                        </Row>


                      </form>
                    </Col>
                    <Col md={3} >
                      <Row className='mt-5'>
                        <Card className={styles.custcarddetails}>
                          <Card.Header className={styles.customercardheader}><b>Veg Menu</b></Card.Header>
                          <Card.Body style={{ fontSize: 'small' }}>
                            <ListGroup.Item className="col-12" >

                            </ListGroup.Item>

                            <ListGroup.Item>
                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b >Sliver</b>
                                </div>
                                <div className='col'>
                                  525
                                </div>
                              </div>
                            </ListGroup.Item>

                            <ListGroup.Item >
                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b>Gold</b>
                                </div>
                                <div className='col'>
                                  625
                                </div>
                              </div>

                            </ListGroup.Item>

                            <ListGroup.Item>

                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b>Diamond</b>
                                </div>
                                <div className='col'>
                                  725
                                </div>
                              </div>
                            </ListGroup.Item>

                          </Card.Body>
                        </Card>
                      </Row>
                      <Row className='mt-5'>
                        <Card className={styles.custcarddetails}>
                          <Card.Header className={styles.customercardheader}><b>Non-Veg Menu</b></Card.Header>
                          <Card.Body style={{ fontSize: 'small' }} >
                            <ListGroup.Item className="col-12" >

                            </ListGroup.Item>

                            <ListGroup.Item>
                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b >Sliver</b>
                                </div>
                                <div className='col'>
                                  725
                                </div>
                              </div>
                            </ListGroup.Item>

                            <ListGroup.Item >
                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b >Gold</b>
                                </div>
                                <div className='col'>
                                  825
                                </div>
                              </div>

                            </ListGroup.Item>

                            <ListGroup.Item>

                              <div className='row'>
                                <div className='col-sm-4 pb-1'>
                                  <b >Diamond</b>
                                </div>
                                <div className='col'>
                                  925
                                </div>
                              </div>
                            </ListGroup.Item>

                          </Card.Body>
                        </Card>
                      </Row>

                    </Col>
                  </Row>
                </div>


              </div>
            </Modal>
          </div>

          <div>

            <Modal open={openCustDet} onClose={handleCloseCust} style={{ width: '100%', height: '100%' }}>

              {handleOpenCust && customerData && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white', position: 'relative' }}>
                  {customerData.map((item, i) => {
                    console.log(typeof (item.bkg_total), "tttttttttt")
                    return (
                      <div className='container-fluid'>

                        <Row style={{ fontFamily: 'bold', marginLeft: '10%' }}
                        >
                          <Col md={8}>

                            <form onSubmit={e => submit(e)} >
                              <Row style={{ fontFamily: 'bold', fontSize: 15 }} className='mt-3'>
                                <Card style={{ width: 750 }}>
                                  <Card.Header className={styles.customercardheader}>
                                    <div className='row mt-2' style={{ fontFamily: 'bold' }}>

                                      <div class="col-3 col-sm-4" >
                                        <b style={{ fontFamily: 'bold' }}> Customer Details </b>
                                      </div>

                                      <div class="col-2 col-sm-2" >
                                        <b style={{ fontFamily: 'bold' }}>{selectedHall} </b>
                                      </div>

                                      <div class="col-2 col-sm-2" >
                                        <b style={{ fontFamily: 'bold' }}> {datevalid} </b>
                                      </div>

                                      <div class="col-2 col-sm-4" >
                                        <b style={{ fontFamily: 'bold' }}>  {item.slot_name === "mrng" ? (
                                          <p>Morning</p>
                                        ) : item.slot_name === "evng" ? (
                                          <p>Evening</p>
                                        ) : (
                                          <p>Unknown time slot</p>
                                        )}</b>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ fontSize: 15 }}>
                                    <Row md={12}>
                                      <Col md={6}>
                                        <Row xs="3" className='mt-2' >
                                          <Col > <b> Name</b></Col>
                                          <Col ><div className={input.formcustomer} >{item.bkg_cust_name}</div></Col>
                                        </Row>
                                        <Row xs="3" className='mt-2'>
                                          <Col> <b>Address</b></Col>
                                          <Col>
                                            <div className={input.formcustomer} style={{ height: 130 }}>{item.bkg_cust_add}</div>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row xs="2" className='mt-2'>
                                          <Col> <b>Email</b></Col>
                                          <Col><div className={input.formcustomer} style={{ height: 50 }}>{item.bkg_cust_email}</div></Col>
                                        </Row>
                                        <Row xs="2" className='mt-2'>
                                          <Col> <b> Mobile Number 1</b></Col>
                                          <Col><div className={input.formcustomer}>{item.bkg_mbn1}</div></Col>
                                        </Row>

                                        <Row xs="2" className='mt-2'>
                                          <Col> <b> Mobile Number 2</b></Col>
                                          <Col><div className={input.formcustomer}>{item.bkg_mbn2}</div></Col>
                                        </Row>
                                        <Row xs="2" className='mt-2'>
                                          <Col> <b> Aadhaar Number</b></Col>
                                          <Col><div className={input.formcustomer}>{item.bkg_adr_num}</div></Col>
                                        </Row>

                                      </Col>
                                    </Row>

                                  </Card.Body>
                                </Card>

                                <Card style={{ width: 750, fontSize: 15 }} className='mt-2' >
                                  <Card.Header className={styles.customercardheader}>

                                    <div className='row mt-2' style={{ fontFamily: 'bold' }}>
                                      <div class="col-2 col-sm-4" >
                                        <b style={{ fontFamily: 'bold' }}> Hall Bookings </b>
                                      </div>

                                      <div class="col-3 col-sm-2" >

                                        <b > {selectedHall} </b>
                                      </div>

                                      <div class="col-2 col-sm-2" >
                                        <b style={{ fontFamily: 'bold' }}>{datevalid} </b>
                                      </div>

                                      <div class="col-2 col-sm-4" >
                                        <b style={{ fontFamily: 'bold' }}>  {item.slot_name === "mrng" ? (
                                          <p>Morning</p>
                                        ) : item.slot_name === "evng" ? (
                                          <p>Evening</p>
                                        ) : (
                                          <p>Unknown time slot</p>
                                        )}</b>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ fontSize: 15 }}>
                                    <Row md={12}>
                                      <Row xs="auto">
                                        <Col>
                                          <Row xs="2" className='mt-2'>
                                            <Col> <b>Time In</b></Col>
                                            <Col><div className={input.formcustomer} style={{ width: 70 }}>{item.bkg_inTime}</div></Col>
                                          </Row>
                                        </Col>
                                        <Col>
                                          <Row xs="2" className='mt-2'>
                                            <Col> <b>Time Out</b></Col>
                                            <Col><div className={input.formcustomer} style={{ width: 70 }}>{item.bkg_outTime}</div></Col>
                                          </Row>
                                        </Col>

                                      </Row>

                                      <Row md={12} className='mt-2'>
                                        <Col md={12}>
                                          <Row xs="6" className="mt-2" style={{ display: "flex", width: "100%" }}>
                                            <Col>
                                              <b>Purpose</b>
                                            </Col>
                                            <Col xs={8} style={{ marginLeft: "-22px" }}>
                                              <div className={input.formcustomer} style={{ width: 490, height: 50 }}>{item.bkg_prpse}</div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>

                                      <Row md={12} className='mt-2'>
                                        <Col md={6}>
                                          <Row xs="4" className='mt-2'>
                                            <Col> <b>Pax</b></Col>
                                            <Col><div className={input.formcustomer}>{item.bkg_pax}</div></Col>
                                          </Row>
                                        </Col>

                                        <Col md={6}>
                                          {
                                            <Row xs="4" className='mt-2'>
                                              <Col> <b>Capacity</b></Col>
                                              {capacityData.map((item) => (
                                                <Col key={item.id}><div className={input.formcustomer}>{item.hall_cpcty}</div></Col>
                                              ))}

                                            </Row>}
                                        </Col>

                                      </Row>

                                      <Row md={12} className='mt-2'>
                                        <Col md={6}>
                                          <Row xs="4" className='mt-2'>
                                            <Col><b>Advance</b></Col>
                                            <Col>
                                              <div className={input.formcustomer}>{Number(item.bkg_advnce).toLocaleString("en-IN")}</div>
                                            </Col>
                                          </Row>

                                        </Col>
                                        <Col md={6}>
                                          <Row xs="4" className='mt-2'>
                                            <Col> <b>Total </b></Col>
                                            <Col><div className={input.formcustomer}> {Number(item.bkg_total).toLocaleString("en-IN")}</div></Col>
                                          </Row>
                                        </Col>
                                      </Row>

                                    </Row>

                                  </Card.Body>
                                </Card>

                              </Row>

                              <Row>
                                <Col md={8}>

                                  <Row xs="4" className='mt-2'>
                                    <b>Booked By</b>
                                    <Col ><div className={input.formcustomer} style={{ width: 250, marginLeft: '-40px' }}>{dataloginname}-{dataloginmobile}</div></Col>
                                  </Row>
                                </Col>
                                <Col md={4}>

                                  <button type="button" className='mt-2' onClick={handleCloseCust} style={{ width: 100, height: 40, backgroundColor: 'green', color: 'white', borderRadius: 5, fontFamily: 'bold', marginLeft: '30%' }}>Back</button>
                                </Col>
                              </Row>


                            </form>
                          </Col>
                          <Col md={3} >
                            <Row className='mt-5' >
                              <Card className={styles.custcarddetails}>
                                <Card.Header className={styles.customercardheader}><b>Veg Menu</b></Card.Header>
                                <Card.Body style={{ fontSize: 15 }}>
                                  <ListGroup.Item className="col-12" >

                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b >Sliver</b>
                                      </div>
                                      <div className='col'>
                                        525
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item >
                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b>Gold</b>
                                      </div>
                                      <div className='col'>
                                        625
                                      </div>
                                    </div>

                                  </ListGroup.Item>

                                  <ListGroup.Item>

                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b>Diamond</b>
                                      </div>
                                      <div className='col'>
                                        725
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                </Card.Body>
                              </Card>
                            </Row>
                            <Row className='mt-5'>
                              <Card className={styles.custcarddetails}>
                                <Card.Header className={styles.customercardheader}><b>Non-Veg Menu</b></Card.Header>
                                <Card.Body style={{ fontSize: 15 }} >
                                  <ListGroup.Item className="col-12" >

                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b >Sliver</b>
                                      </div>
                                      <div className='col'>
                                        725
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item >
                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b >Gold</b>
                                      </div>
                                      <div className='col'>
                                        825
                                      </div>
                                    </div>

                                  </ListGroup.Item>

                                  <ListGroup.Item>

                                    <div className='row'>
                                      <div className='col-sm-4 pb-1'>
                                        <b >Diamond</b>
                                      </div>
                                      <div className='col'>
                                        925
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                </Card.Body>
                              </Card>
                            </Row>

                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              )}



            </Modal>
          </div>
        </Row>
      </div>


    </div>
  );
}

export default ResponsiveExample;