// import { useState } from 'react';
// import Row from 'react-bootstrap/Row';
// import table from '../Styles/Table.module.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// function MyComponent() {
//   const [selectedDay, setSelectedDay] = useState(null);
//   const date = new Date('Sat Apr 01 2023 00:00:00 GMT+0530');
//   const formattedDate = date.toLocaleDateString();
//   const trimmedDate = formattedDate.substring(0, formattedDate.indexOf(' '));
//   function handleDateClick(date) {
//     console.log("Selected date:", date);
    
   
//   }
//   function getTileClassName({ date, view }) {
//     if (view === 'month' && isWeekend(date)) {
//         return 'weekend';
//     }
// }
 
//     function isWeekend(date) {
//       const day = date.getDay();
//       return day === 0 || day === 6;
//       console.log("seeeeeeeeee", day)
//   }
//   return (
//     <>
//       <Calendar
//         value={selectedDay}
//         onChange={handleDateClick}
//         tileClassName={getTileClassName}
//       />
//       {selectedDay && (
//         <Row className="justify-content-md-center mt-2">
//           <table style={{ width: 500 }}>
//             <tr className={table.projectstatustr}>
//               <th>Hall Names</th>
//               <th>Slot 1</th>
//               <th>Slot 2</th>
//             </tr>
//             <tr className={table.projectstatustr}>
//               <td>Dharani Hall:</td>
//               <td>
//                 <button
//                   style={{
//                     backgroundColor: 'green',
//                     color: 'white',
//                     width: 100,
//                     height: 40,
//                     borderRadius: 10,
//                     fontFamily: 'bold',
//                   }}
//                 >
//                   Morning
//                 </button>
//               </td>
//               <td>
//                 <button
//                   style={{
//                     backgroundColor: 'grey',
//                     color: 'white',
//                     width: 100,
//                     height: 40,
//                     borderRadius: 10,
//                     fontFamily: 'bold',
//                   }}
//                 >
//                   Afternoon
//                 </button>
//               </td>
//             </tr>
     
//           </table>
//         </Row>
//       )}
//     </>
//   );
// }
// export default MyComponent;

// import React from 'react';

// function App() {
//   const date = new Date('Sat Apr 01 2023 00:00:00 GMT+0530');
//   const formattedDate = date.toLocaleDateString();
//   const trimmedDate = formattedDate.substring(0, formattedDate.indexOf(' '));

//   return (
//     <div>
//       <p>Full date: {formattedDate}</p>
//       <p>Trimmed date: {trimmedDate}</p>
//     </div>
//   );
// }

// export default App;    