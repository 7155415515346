// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n .Card_custcarddetails__QSzmr{\r\n      font-family: bold;\r\n      cursor: default; \r\n      width:300px  \r\n    }\r\n    .Card_customercardheader__dCWg2{\r\n        background-color:  #dcdfe0;\r\n        font-family: 'bold';\r\n        text-align: center;\r\n        font-size: medium;\r\n        color: black;\r\n        height:50px;\r\n      }\r\n      .Card_slotcustdetails__x1G3u{\r\n        width:600px;\r\n        font-family: 'bold';\r\n        /* margin-top: 50px; */\r\n        /* margin-left: 250px; */\r\n      }\r\n", "",{"version":3,"sources":["webpack://./src/Styles/Card.module.css"],"names":[],"mappings":";CACC;MACK,iBAAiB;MACjB,eAAe;MACf;IACF;IACA;QACI,0BAA0B;QAC1B,mBAAmB;QACnB,kBAAkB;QAClB,iBAAiB;QACjB,YAAY;QACZ,WAAW;MACb;MACA;QACE,WAAW;QACX,mBAAmB;QACnB,sBAAsB;QACtB,wBAAwB;MAC1B","sourcesContent":["\r\n .custcarddetails{\r\n      font-family: bold;\r\n      cursor: default; \r\n      width:300px  \r\n    }\r\n    .customercardheader{\r\n        background-color:  #dcdfe0;\r\n        font-family: 'bold';\r\n        text-align: center;\r\n        font-size: medium;\r\n        color: black;\r\n        height:50px;\r\n      }\r\n      .slotcustdetails{\r\n        width:600px;\r\n        font-family: 'bold';\r\n        /* margin-top: 50px; */\r\n        /* margin-left: 250px; */\r\n      }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custcarddetails": "Card_custcarddetails__QSzmr",
	"customercardheader": "Card_customercardheader__dCWg2",
	"slotcustdetails": "Card_slotcustdetails__x1G3u"
};
export default ___CSS_LOADER_EXPORT___;
