// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_logoutbutton__TqBLh{\r\n    background-color: #b51a05;\r\n    width:80px;\r\n    height: 30px;\r\n    border-radius: 30px;\r\n    font-family: 'bold';\r\n    color:white\r\n}\r\n.Navbar_navnamecenter__Mc3TP{\r\n    font-family: 'bold';\r\n    font-size: x-large;\r\n    color: black;\r\n    margin-left: 550px;\r\n}\r\n.Navbar_navdropdown__Ba7V8{\r\n    /* background-color: #01AF57; */\r\n    /* border-radius: '0.1rem'; */\r\n    font-family: bold;\r\n    width: 70px !important;\r\n    height:24px !important;\r\n    margin-right: 10px !important;\r\n    \r\n}\r\n.Navbar_navstyle__AvNn4{\r\n    color:black;\r\n    font-family: 'bold';\r\n    /* font-size: larger; */\r\n  }", "",{"version":3,"sources":["webpack://./src/Styles/Navbar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB;AACJ;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,+BAA+B;IAC/B,6BAA6B;IAC7B,iBAAiB;IACjB,sBAAsB;IACtB,sBAAsB;IACtB,6BAA6B;;AAEjC;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":[".logoutbutton{\r\n    background-color: #b51a05;\r\n    width:80px;\r\n    height: 30px;\r\n    border-radius: 30px;\r\n    font-family: 'bold';\r\n    color:white\r\n}\r\n.navnamecenter{\r\n    font-family: 'bold';\r\n    font-size: x-large;\r\n    color: black;\r\n    margin-left: 550px;\r\n}\r\n.navdropdown{\r\n    /* background-color: #01AF57; */\r\n    /* border-radius: '0.1rem'; */\r\n    font-family: bold;\r\n    width: 70px !important;\r\n    height:24px !important;\r\n    margin-right: 10px !important;\r\n    \r\n}\r\n.navstyle{\r\n    color:black;\r\n    font-family: 'bold';\r\n    /* font-size: larger; */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoutbutton": "Navbar_logoutbutton__TqBLh",
	"navnamecenter": "Navbar_navnamecenter__Mc3TP",
	"navdropdown": "Navbar_navdropdown__Ba7V8",
	"navstyle": "Navbar_navstyle__AvNn4"
};
export default ___CSS_LOADER_EXPORT___;
