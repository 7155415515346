// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_forminput__GEzgg{\r\n    width:170px ;\r\n    height:35px;\r\n    border-radius: 0.3rem;\r\n    background-color: rgb(225, 225, 225);\r\n    border:none;\r\n}\r\n.Form_formcustomer__Nt0hI{\r\n    width:150px ;\r\n    height:35px;\r\n    border-radius: 0.3rem;\r\n    background-color: rgb(225, 225, 225);\r\n    border:none;\r\n    color:black;\r\n   text-align: center;\r\n   padding-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Styles/Form.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,oCAAoC;IACpC,WAAW;AACf;AACA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,oCAAoC;IACpC,WAAW;IACX,WAAW;GACZ,kBAAkB;GAClB,iBAAiB;AACpB","sourcesContent":[".forminput{\r\n    width:170px ;\r\n    height:35px;\r\n    border-radius: 0.3rem;\r\n    background-color: rgb(225, 225, 225);\r\n    border:none;\r\n}\r\n.formcustomer{\r\n    width:150px ;\r\n    height:35px;\r\n    border-radius: 0.3rem;\r\n    background-color: rgb(225, 225, 225);\r\n    border:none;\r\n    color:black;\r\n   text-align: center;\r\n   padding-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forminput": "Form_forminput__GEzgg",
	"formcustomer": "Form_formcustomer__Nt0hI"
};
export default ___CSS_LOADER_EXPORT___;
