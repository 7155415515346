/* eslint-disable */
import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { useNavigate } from "react-router-dom";
import bar from '../../src/Styles/Navbar.module.css'

function Navbar(props) {
  // const navigate = useNavigate();
  // const handleNavlogout = () => {
  //   navigate('/BookingSlots')
  // }
  return (
    <nav className="navbar navbar-expand-lg navbar-fixed-top" style={{ backgroundColor: '#8CC0DE',position:'sticky' }}>
      <div className="container-fluid">
        {/* <a href="/" class="navbar-brand">
          <img src={logo} alt="" style={{ height: 30, width: 30, borderRadius: 30 }} />
        </a> */}

        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav">
            {/* <div style={{color:'black',fontFamily:'bold',fontSize:20,}}>Sree Banquets Restaurant</div> */}
            <div className={bar.navnamecenter}><div >Sree Banquets Restaurant
        </div></div>
          </div>
          {/* <div class="navbar-nav ms-auto">
            <button className={bar.logoutbutton} onClick={handleNavlogout}>Login</button>
          </div> */}

        </div>
      </div>
    </nav>

  );
}
export default Navbar;