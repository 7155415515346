/* eslint-disable */
import { React, useState } from 'react';
// import './SideNavbar.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from 'reactstrap';
import {
   Nav,
  NavItem,

  NavLink,
} from 'reactstrap';
import { Sidenav } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import bar from '../../src/Styles/Navbar.module.css'

function Navbar() {

  const navigate = useNavigate();
  const handleNav1 = () => {
    navigate('/')
  }
  const handleNavlogout = () => {
    // Show confirmation dialog box
    const shouldLogout = window.confirm("Are you sure you want to log out?");
  
    // Check the user's choice
    if (shouldLogout) {
      // User confirmed, perform logout
      // Add your logout logic here
      console.log("Logging out...");
    } else {
      // User canceled, do nothing
      console.log("Logout canceled.");
    }
  };
  

  const [selectedNavItem, setSelectedNavItem] = useState("");

  const handleNavSelect = (eventKey) => {
    setSelectedNavItem(eventKey);
  };
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    setOpen(!open);
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const handleLogouthome = () => {
    // Show confirmation dialog box
    const shouldLogout = window.confirm("Are you sure you want to log out?");
  
    // Check the user's choice
    if (shouldLogout) {
      // User confirmed, perform logout
      // Add your logout logic here
      console.log("Logging out...");
      // Navigate to the desired location after logout
      window.location.href = "/logout";
    } else {
      // User canceled, update the URL without adding to history
      console.log("Logout canceled.");
      window.location.replace("/"); // Replace with the appropriate URL for your home screen
    }
  };
  
  
  
  
  
  const handleNavBookingSlots = () => {
    navigate('/BookingSlots')
  }
  const handleNavDharani = () => {
    navigate('/DharaniHall')
  }
  const handleNavDhatri = () => {
    navigate('/DathriHall')
  }
  const handleNavBhuvi = () => {
    navigate('/BhuviHall')
  }
  const handleNavAvani = () => {
    navigate('/AvaniHall')
  }
 
  return (

    <div >
      <Sidenav activeKey={selectedNavItem} onSelect={handleNavSelect} style={{ height: '100%'}}>
        <Sidenav.Body >
          <Accordion open={open} toggle={toggle} style={{ fontFamily: 'bold' }}>

            {/* <AccordionItem >

              <Nav vertical>
                <NavItem style={{marginBottom:20,marginTop:20}}>
                  
                  <NavLink href="/" style={{ color: 'black',cursor:'pointer' }} onClick={handleLogouthome}>  
                 <b>Home</b>
                  </NavLink>
                </NavItem>
              </Nav>

            </AccordionItem> */}

            <AccordionItem>

              <Nav vertical>
                <NavItem style={{marginBottom:20,marginTop:20,cursor:'pointer',marginLeft:20}} onClick={handleNavBookingSlots}>
                  {/* <NavLink href="/BookingSlots" style={{ color: 'black' }}> */}
                    {/* <BsPersonCircle /> */}
                   <b> Booking Slots</b>
                  {/* </NavLink> */}
                </NavItem>
              </Nav>

            </AccordionItem>

            <AccordionItem>
              <AccordionHeader targetId="3" style={{ color: 'black',marginTop:20 }}><b>Booking Halls</b></AccordionHeader>
              <AccordionBody accordionId="3" style={{ color: 'black' }}>
                <Nav vertical style={{ color: 'black',marginTop:15  }}>
                  <NavItem onClick={handleNavDharani} style={{ color: 'black' ,cursor:'pointer' }}>
                    {/* <NavLink href="/DharaniHall" style={{ color: 'black' }}> */}
                      {/* <AiFillSignal /> */}
                    <b>  Dharani Hall</b>
                    {/* </NavLink> */}
                  </NavItem>
                  <NavItem onClick={handleNavDhatri} style={{ color: 'black',marginTop:20 ,cursor:'pointer'  }}>
                    {/* <NavLink href="/DathriHall" style={{ color: 'black' }}> */}
                      {/* <AiFillSignal /> */}
                      <b>Dhatri Hall</b>
                    {/* </NavLink> */}
                  </NavItem>
                  <NavItem onClick={handleNavBhuvi} style={{ color: 'black',marginTop:20 ,cursor:'pointer'  }}>
                    {/* <NavLink href="/BhuviHall" style={{ color: 'black' }}> */}
                      {/* <AiFillSignal /> */}
                      <b>Bhuvi Hall</b>
                    {/* </NavLink> */}
                  </NavItem>
                  <NavItem onClick={handleNavAvani} style={{ color: 'black',marginTop:20 ,cursor:'pointer'  }}>
                    {/* <NavLink href="/AvaniHall" style={{ color: 'black' }}> */}
                      {/* <AiFillSignal /> */}
                     <b> Avani Hall</b>
                    {/* </NavLink> */}
                  </NavItem>

                </Nav>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem>

              <Nav vertical>
                <NavItem style={{marginLeft:20,marginBottom:20}}>

                  {/* <AiFillSignal /> */}
                  <div class="navbar-nav ms-auto" className='mt-4'>
                    <button className={bar.logoutbutton} onClick={handleNavlogout}>Logout</button>
                  </div>

                </NavItem>
              </Nav>

            </AccordionItem>

          </Accordion>
        </Sidenav.Body>
      </Sidenav>

    </div>
  );
}

export default Navbar;