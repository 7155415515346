// import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import BookingHalls from '../src/Component/BookingHalls';
import Home from '../src/Component/Home';
import BookingSlots from '../src/Component/BookingSlots';
import DharaniHall from '../src/Component/DharaniHall';
import DathriHall from '../src/Component/DathriHall';
import BhuviHall from '../src/Component/BhuviHall';
import AvaniHall from '../src/Component/AvaniHall';
import Login from './Component/Login';
function App() {
  return (
 
  
     <BrowserRouter>
     <main >
      <div className=''>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/Login' element={<Login/>} />
        <Route path='/BookingHalls' element={<BookingHalls/>} />
        <Route path='/BookingSlots' element={<BookingSlots/>} />
        <Route path='/DharaniHall' element={<DharaniHall/>} />
        <Route path='/DathriHall' element={<DathriHall/>} />
        <Route path='/BhuviHall' element={<BhuviHall/>} />
        <Route path='/AvaniHall' element={<AvaniHall/>} />
      </Routes>
      </div>
      </main>
      </BrowserRouter>
  
  );
}

export default App;

