/* eslint-disable */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import w1 from '../../src/assests/w1.jpg'
import wedding from '../../src/assests/wedding.webp'
import birthday from '../../src/assests/birthday.jpg'
import s from '../../src/assests/s.jpg'
import c from '../../src/assests/c.jpg'
import nonveg from '../../src/assests/nonveg.webp'
import veg from '../../src/assests/veg.webp'
import { Container, Header, Content } from 'rsuite';
import { useNavigate } from "react-router-dom";
import bar from '../../src/Styles/Navbar.module.css'

function App() {

    const navigate = useNavigate();
    const handleNavlogout = () => {
        navigate('/Login')
    }

    const [idedit, setidedit] = useState("");

    function EditImage(item) {
        console.log(item, "data");

        setidedit(item.id);

    }

    return (

        <center>
            <div style={{ overflow: "hidden" }}>
                <Header >
                    <nav className="navbar navbar-expand-lg navbar-fixed-top" style={{ backgroundColor: '#8CC0DE', position: 'sticky' }}>
                        <div className="container-fluid">

                            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <div className="navbar-nav">
                                    <div className={bar.navnamecenter}><div >Sree Banquets Restaurant
                                    </div></div>
                                </div>
                                <div class="navbar-nav ms-auto">
                                    <button className={bar.logoutbutton} onClick={handleNavlogout}>Login</button>
                                </div>

                            </div>
                        </div>
                    </nav>

                </Header>

                <Container>

                    <Content>
                        <div className='row mt-3'>
                            <Carousel variant="dark" controls={false}>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100 "
                                        style={{ height: 400 }}
                                        src={w1}
                                        alt="Second slide"
                                    />
                                    <Carousel.Caption>
                                        <div style={{ marginBottom: 100 }}>
                                            <h3 style={{ color: 'black', fontFamily: 'Brush Script MT, Brush Script Std, cursive', marginLeft: '65%', fontSize: 40 ,fontWeight:500}}>Banquet and Halls</h3>
                                            <h5 style={{ color: 'black', fontFamily: 'Brush Script MT, Brush Script Std, cursive', marginLeft: '65%', fontSize: 30 ,fontWeight:500}}>For All Occasions</h5>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>

                            </Carousel>
                        </div>

                        <Row className='container-fluid mt-3' style={{ fontFamily: 'bold' }}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col style={{ color: 'black' }} >
                                                <div ><h2 style={{ fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: 35 }}>Aminities</h2></div>
                                                {/* <div><h2>thereafter, They Shape Us...</h2></div> */}

                                                <ListGroup.Item className="col-12" >
                                                    <div className='row'>
                                                        * 4 Changing rooms avaliable ,one for each.
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item className="col-12" >
                                                    <div className='row' >
                                                        * Decoration, Photography, Sonnayi and all other services are avaliable.
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item >
                                                    <div className='row'>
                                                        * Can accomodate upto 800 pax, 2 halls for event area and 1 hall for dinning.
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <div className='row'>
                                                        * Separate hand wash area for each hall to avoid chaos.
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <div className='row'>
                                                        * LED Counter tables for buffet.
                                                    </div>
                                                </ListGroup.Item>

                                                <Row>
                                                    <div ><h2 style={{ fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: 35 }}>Halls And Pax</h2></div>
                                                    <div style={{marginLeft:'23%'}}>
                                                    <ListGroup.Item>
                                                        <div className='row'>
                                                            <div className='col-sm-4 pb-1'>
                                                                <div>Dharani Hall</div>
                                                            </div>
                                                            <div className='col-2'>
                                                                525
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>

                                                    <ListGroup.Item >
                                                        <div className='row'>
                                                            <div className='col-sm-4 pb-1'>
                                                                <div>Dhatri Hall</div>
                                                            </div>
                                                            <div className='col-2'>
                                                                625
                                                            </div>
                                                        </div>

                                                    </ListGroup.Item>

                                                    <ListGroup.Item>
                                                        <div className='row'>
                                                            <div className='col-sm-4 pb-1'>
                                                                <div>Bhuvi Hall</div>
                                                            </div>
                                                            <div className='col-2'>
                                                                725
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>

                                                    <ListGroup.Item>
                                                        <div className='row'>
                                                            <div className='col-sm-4 pb-1'>
                                                                <div>Avani Hall</div>
                                                            </div>
                                                            <div className='col-2'>
                                                                725
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                    </div>
                                                </Row>

                                            </Col>
                                            <Col >
                                                <Card.Img src="https://cdn0.weddingwire.in/vendor/3697/3_2/960/jpg/banquet-halls-shagun-banquet-hall-marriage-garden-event-space-4_15_343697-159975509021166.jpeg" alt="" style={{ width: 500, height: 350 }} />
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>

                            </Card>
                        </Row>

                        <div className='row'>
                            <h3 style={{ color: 'black', fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: 35 }}>Services</h3>
                        </div>

                        <div class="row row-cols-4" >
                            <div class="col">
                                <img src={wedding} alt='' width={320} height={200} style={{ borderRadius: 30 }} />
                                <div><h5 className="container-img mt-2" style={{ fontFamily: 'cursives', color: 'black' }} ><b>Wedding Ceremony</b></h5></div>
                            </div>
                            <div class="col"  >
                                <img src={birthday} alt='' width={320} height={200} style={{ borderRadius: 30 }} />
                                <div><h5 className="container-img mt-2" style={{ fontFamily: 'cursives', color: 'black' }} ><b>Birthday Partys</b></h5></div>
                            </div>
                            <div class="col">
                                <img src={s} alt='' width={320} height={200} style={{ borderRadius: 30 }} />
                                <div><h5 className="container-img mt-2" style={{ fontFamily: 'cursives', color: 'black' }} ><b>Social Events</b></h5></div>
                            </div>
                            <div class="col">
                                <img src={c} alt='' width={320} height={200} style={{ borderRadius: 30 }} />
                                <div><h5 className="container-img mt-2" style={{ fontFamily: 'cursives', color: 'black' }} ><b>Corporate Events</b></h5></div>
                            </div>
                        </div>

                        <Row className='container-fluid mt-3' style={{ fontFamily: 'bold' }}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col style={{ color: 'black' }} >
                                                <div ><h2 style={{ fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: 35 }}>Veg Menu</h2></div>
                                                 <div style={{marginLeft:'30%'}}>
                                                <ListGroup.Item>
                                                    <div className='row mt-3'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b >Sliver</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            525
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item >
                                                    <div className='row'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b>Gold</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            625
                                                        </div>
                                                    </div>

                                                </ListGroup.Item>

                                                <ListGroup.Item>

                                                    <div className='row'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b>Diamond</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            725
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                </div>
                                            </Col>
                                            <Col >
                                                <Card.Img src={veg} alt="" style={{ width: 500, height: 250 }} />
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>

                            </Card>
                        </Row>

                        <Row className='container-fluid mt-3' style={{ fontFamily: 'bold' }}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col >
                                                <Card.Img src={nonveg} alt="" style={{ width: 500, height: 250 }} />
                                            </Col>
                                            <Col style={{ color: 'black' }} >
                                                <div ><h2 style={{ fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: 35 }}>Non-Veg Menu</h2></div>
                                                <div style={{marginLeft:'30%'}}>
                                                <ListGroup.Item>
                                                    <div className='row mt-3'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b >Sliver</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            725
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>

                                                <ListGroup.Item >
                                                    <div className='row'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b>Gold</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            825
                                                        </div>
                                                    </div>

                                                </ListGroup.Item>

                                                <ListGroup.Item>

                                                    <div className='row'>
                                                        <div className='col-sm-4 pb-1'>
                                                            <b>Diamond</b>
                                                        </div>
                                                        <div className='col-2'>
                                                            925
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>

                            </Card>
                        </Row>


                    </Content>


                </Container>

            </div>
            <br></br><br />
        </center>

    );
}

export default App;